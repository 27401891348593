import modalHTML from './modal.html?raw';
import {htmlToElement, triggerEvent} from '@/functions';

function modalDialog(options) {
    const dialog = htmlToElement(modalHTML.replace('__CONTENT__', options.content.innerHTML));

    dialog.addEventListener('closed', () => setTimeout(() => dialog.remove(), 1000));

    document.body.appendChild(dialog);

    window.requestAnimationFrame(() => triggerEvent(dialog, 'open'));
}

document.querySelectorAll('[data-modal]').forEach(node => {
    node.addEventListener('click', e => {
        e.preventDefault();

        const content = document.querySelector(node.getAttribute('data-modal'));

        modalDialog({content});
    });
});
