import nprogress from 'nprogress';

const body = document.getElementsByTagName('body')[0];

window.nprogress = nprogress;

nprogress.configure({
    showSpinner: false,
});

window._nprogress_counter = 0;

window.progress_start = () => {
    window._nprogress_counter++;

    if (window._nprogress_counter) {
        body.classList.add('progress');

        nprogress.start();
    }
};

window.progress_end = () => {
    window._nprogress_counter--;

    if (!window._nprogress_counter) {
        body.classList.remove('progress');

        window._nprogress_counter = 0;

        nprogress.done();
    }
};
