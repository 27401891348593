import Vue from 'vue';
import tippy from 'tippy.js';
import isMobile from '@/helpers/isMobile';
import ClipboardJS from 'clipboard';

const initTooltip = (el, binding) => {
    destroyTooltip(el);

    const t = [];

    if (binding.modifiers.focus) t.push('focus');
    if (binding.modifiers.hover) t.push('mouseenter');
    if (binding.modifiers.click) t.push('click');
    if (!t.length) t.push('mouseenter', 'focus');

    tippy(el, {
        content: binding.value,
        trigger: t.join(' '),
        placement: binding.arg || 'top',
        allowHTML: !!binding.modifiers.html,
    });
};

const destroyTooltip = el => {
    el._tippy && el._tippy.destroy();
};

Vue.directive('tooltip', {
    bind: initTooltip,
    update: initTooltip,
    unbind: destroyTooltip,
});

Vue.directive('autofocus', {
    inserted: isMobile()
        ? () => {}
        : function (el, binding) {
              if (binding.value !== undefined && !binding.value) {
                  return;
              }

              el.focus();
          },
});

const tooltipText = 'Copied!';
const tooltipTimeout = 1000;

const setupClipboard = function (el, binding) {
    destroyTooltip(el);

    const options = {};

    if (binding.value) {
        options.text = () => binding.value;
    } else if (el.hasAttribute('data-clipboard-value')) {
        options.text = () => el.getAttribute('data-clipboard-value');
    } else {
        options.target = () => document.querySelector(targetSelector);
    }

    const targetSelector = el.getAttribute('data-clipboard');

    el._clipboard = new ClipboardJS(el, options);

    el._clipboardTooltip = tippy(el, {
        content: tooltipText,
        trigger: 'manual',
    });

    el._clipboard.on('success', () => {
        el._clipboardTooltip.show();

        setTimeout(el._clipboardTooltip.hide, tooltipTimeout);
    });
};
const destroyClipboard = function (el) {
    if (el._clipboard) {
        el._clipboard.destroy();
    }

    if (el._clipboardTooltip) {
        el._clipboardTooltip.destroy();
    }
};

Vue.directive('clipboard', {
    bind: setupClipboard,
    update: setupClipboard,
    unbind: destroyClipboard,
});
