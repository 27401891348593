import VueRouter from 'vue-router';
import {updatePageTitle} from '@/helpers/vue';

const router = new VueRouter({
    mode: 'history',
    base: '/team/' + (window.TEAM ? window.TEAM.slug : 'undefined'),
    routes: [
        {
            name: 'apps',
            path: '/apps',
            meta: {
                title: 'Applications',
            },
            component: () => import('@/pages/applications/index.vue'),
        },
        {
            name: 'apps.create',
            path: '/apps/create',
            meta: {
                title: 'Create an app',
            },
            component: () => import('@/pages/applications/createOrEdit.vue'),
        },
        {
            name: 'apps.single',
            path: '/apps/:id',
            meta: {
                title: 'App',
            },
            props: route => ({
                id: route.params.id,
            }),
            component: () => import('@/pages/applications/single.vue'),
        },
        {
            name: 'apps.edit',
            path: '/apps/:id/edit',
            meta: {
                title: 'Edit app',
            },
            props: route => ({
                id: route.params.id,
                returnTo: route.params.returnTo,
            }),
            component: () => import('@/pages/applications/createOrEdit.vue'),
        },

        {
            name: 'apps.servers.create',
            path: '/apps/:aid/servers/create',
            meta: {
                title: 'Create server',
            },
            props: route => ({
                appId: route.params.aid,
            }),
            component: () => import('@/pages/applications/servers/createOrEdit.vue'),
        },
        {
            name: 'apps.servers.edit',
            path: '/apps/:aid/servers/:sid/edit',
            meta: {
                title: 'Edit server',
            },
            props: route => ({
                appId: route.params.aid,
                serverId: route.params.sid,
            }),
            component: () => import('@/pages/applications/servers/createOrEdit.vue'),
        },

        {
            name: 'apps.environments.create',
            path: '/apps/:aid/environments/create',
            meta: {
                title: 'Create environment',
            },
            props: route => ({
                appId: route.params.aid,
            }),
            component: () => import('@/pages/applications/environments/createOrEdit.vue'),
        },
        {
            name: 'apps.environments.edit',
            path: '/apps/:aid/environments/:eid/edit',
            meta: {
                title: 'Edit environment',
            },
            props: route => ({
                appId: route.params.aid,
                environmentId: route.params.eid,
            }),
            component: () => import('@/pages/applications/environments/createOrEdit.vue'),
        },
        {
            name: 'apps.environments.edit.env',
            path: '/apps/:aid/environments/:eid/env',
            meta: {
                title: 'Edit environment file',
            },
            props: route => ({
                appId: route.params.aid,
                environmentId: route.params.eid,
            }),
            component: () => import('@/pages/applications/environments/edit.vue'),
        },
        {
            name: 'apps.environments.edit.scripts',
            path: '/apps/:aid/environments/:eid/scripts',
            meta: {
                title: 'Edit environment scripts',
            },
            props: route => ({
                appId: route.params.aid,
                environmentId: route.params.eid,
            }),
            component: () => import('@/pages/applications/environments/editScripts.vue'),
        },
        {
            name: 'apps.environments.edit.scripts.strategy',
            path: '/apps/:aid/environments/:eid/scripts/:sid/strategy',
            meta: {
                title: 'Edit environment script strategy',
            },
            props: route => ({
                appId: route.params.aid,
                environmentId: route.params.eid,
                scriptConnectionId: route.params.sid,
                returnTo: route.params.returnTo,
            }),
            component: () => import('@/pages/applications/environments/editScriptStrategy.vue'),
        },

        {
            name: 'apps.deployments.single',
            path: '/apps/:aid/deployments/:did/view',
            meta: {
                title: 'Deployment',
            },
            props: route => ({
                appId: route.params.aid,
                deploymentId: route.params.did,
            }),
            component: () => import('@/pages/applications/deployments/single.vue'),
        },
        {
            name: 'apps.deployments.create',
            path: '/apps/:aid/deployments/:eid/create',
            meta: {
                title: 'New deployment',
            },
            props: route => ({
                appId: route.params.aid,
                environmentId: route.params.eid,
            }),
            component: () => import('@/pages/applications/deployments/create.vue'),
        },

        {
            name: 'scripts',
            path: '/scripts',
            meta: {
                title: 'Scripts',
            },
            component: () => import('@/pages/scripts/index.vue'),
        },
        {
            name: 'scripts.create',
            path: '/scripts/create',
            meta: {
                title: 'Create a script',
            },
            component: () => import('@/pages/scripts/createOrEdit.vue'),
        },
        {
            name: 'scripts.edit',
            path: '/scripts/:id/edit',
            meta: {
                title: 'Edit script',
            },
            props: route => ({
                id: route.params.id,
                returnTo: route.params.returnTo,
            }),
            component: () => import('@/pages/scripts/createOrEdit.vue'),
        },

        {
            name: 'errors.404',
            path: '/*',
            component: () => import('@/pages/errors/404.vue'),
        },
        {
            name: 'errors.placeholder',
            path: '/error',
        },
    ],
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
        progress_start();
    }

    next();
});

router.afterEach((to, from) => {
    progress_end();

    if (to.matched.length) {
        updatePageTitle(to.meta.title);
    }
});

export const deployChiefRouter = router;
