import './lib/sentry';

import Vue from 'vue';
import {each} from 'lodash-es';
import VueApollo from 'vue-apollo';
import VueTimeago from 'vue-timeago';
import apolloProvider from '@/api/client';
import {updatePageTitle} from '@/helpers/vue';
import {mapValues, keyBy} from 'lodash-es';
import {deployChiefRouter} from '@/router';
import VueRouter, {RouterView} from 'vue-router';
import {log, logWarn, logError} from '@/lib/log';

Vue.config.productionTip = false;

import './bootstrap';
import './directives';
import './filters';

Vue.use(VueRouter);
Vue.use(VueApollo);
Vue.use(VueTimeago, {locale: 'en'});

Vue.mixin({
    methods: {
        abort(code) {
            const path = this.$route.path;
            const query = this.$route.query;

            switch (code) {
                case 404:
                    // Noop. These codes are supported!
                    break;
                default:
                    logError(`Cannot abort with code:${code}`);
                    return;
            }

            // Workaround because redirecting to the exact same path is not working correctly
            this.$router.replace({name: 'errors.placeholder', replace: true});
            this.$router.replace({name: `errors.${code}`, params: {0: path}, query: query, replace: true});

            return null;
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },
        updatePageTitle: updatePageTitle,

        mapErrorsFromResponse(response) {
            return mapValues(
                keyBy(response.status.errors, error => error.name),
                error => error.messages
            );
        },
    },
});

if (document.getElementById('app-container')) {
    new Vue({
        el: '#app-root',
        name: 'DeployChief',
        router: deployChiefRouter,
        render: h => h(RouterView),
        mounted() {
            let input = document.querySelector('[autofocus]');

            if (input) {
                input.focus();
            }

            // Handle anchor links with vue-router
            window.addEventListener('click', event => {
                // Ensure we use the link, in case the click has been received by a subelement
                let {target} = event;

                while (target && target.tagName !== 'A') {
                    target = target.parentNode;
                }

                if (!target) {
                    return;
                }

                const routerTarget = target.getAttribute('vue-href');

                // Only handle links tagged with vue-href
                if (routerTarget) {
                    // some sanity checks taken from vue-router:
                    // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
                    const {altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented} = event;

                    // don't handle with control keys
                    if (metaKey || altKey || ctrlKey || shiftKey) {
                        return;
                    }

                    // don't handle when preventDefault called
                    if (defaultPrevented) {
                        return;
                    }

                    // don't handle right clicks
                    if (button !== undefined && button !== 0) {
                        return;
                    }

                    // don't handle if `target="_blank"`
                    if (target && target.getAttribute) {
                        const linkTarget = target.getAttribute('target');

                        if (/\b_blank\b/i.test(linkTarget)) {
                            return;
                        }
                    }

                    let route = null;

                    try {
                        route = JSON.parse(routerTarget);
                    } catch {
                        logWarn('Invalid Vue router target', routerTarget);

                        return;
                    }

                    event.preventDefault();

                    if (this.$router.currentRoute && this.$router.currentRoute.name === route.name) {
                        logWarn('Prevent redirecting to same route again', routerTarget);

                        return;
                    }

                    log('Redirected anchor to Vue router', route);

                    this.$router.push(route);

                    // Cleanup active link status
                    each(document.querySelectorAll('a.router-link-active[vue-href]'), elem => {
                        elem.classList.remove('router-link-active');
                    });

                    target.classList.add('router-link-active');
                }
            });
        },
        apolloProvider,
    });
}
