import Vue from 'vue';
import {format, isToday, parseISO, isThisYear, formatDistanceStrict} from 'date-fns';

Vue.filter('formatTime', function (value) {
    return format(parseISO(value), 'HH:mm');
});

Vue.filter('formatDateNice', function (value) {
    const parsedDate = parseISO(value);

    if (isThisYear(parsedDate)) {
        return format(parsedDate, 'dd-MM');
    }

    return format(parsedDate, 'dd-MM-yyyy');
});

Vue.filter('formatDateTimeNice', function (value) {
    const parsedDate = parseISO(value);

    if (isToday(parsedDate)) {
        return format(parsedDate, 'HH:mm');
    }

    if (isThisYear(parsedDate)) {
        return format(parsedDate, "HH:mm 'on' dd-MM");
    }

    return format(parsedDate, "HH:mm 'on' dd-MM-yyyy");
});

Vue.filter('formatDistance', function (value, to) {
    return formatDistanceStrict(parseISO(value), parseISO(to));
});

Vue.filter('truncate', function (value, limit, indicator) {
    limit = limit || 16;
    indicator = indicator || '...';

    return value.length > limit ? value.substr(0, limit) + indicator : value;
});
